<template>
    <div id="Withdrawal" class="has_fixed_bottom_box">
        <div class="px-3 pt-0">
            <div class="flex flex-column w-100 text-center py-0 mb-0 form-group">

                <select class="form-control mt-0 primary" v-model="selected_coin" disabled>
                    <option :value="item" v-for="(item,index) in coin_list" :key="index">{{item}}</option>
                </select>
                <div class="form-group mt-2 mb-1">
                    <div class="r_btn">
                        <input type="text" class="form-control" id="address_to" :placeholder="$t('transaction.46')" spellcheck="false">
                        <button class="btn flex">
                            <div @click="infoPpOn()">
                                <i class="far fa-address-book mr-2"></i>
                            </div>
                            <div @click="runCamera()">
                                <i class="far fa-qrcode"></i>
                            </div>
                        </button>
                    </div>
                </div>
                <div class="payment-get" v-if="camera_area">
                    <button type="button" class="btn btn-block btn-danger mb-2" @click="CancelQr"><small>Cancel</small></button>
                    <qrcode-reader class="border border-secondary rounded" style="border-width:2px !important;" :camera="camera" @decode="onDecode"></qrcode-reader>
                </div>

                <div class="px-2 pb-2">
                    <div class="form-group inline line mb-0">
                        <label class="">{{$t('transaction.34')}}</label>
                        <input type="text" class="form-control text-right" :value="selected_coin_num" disabled>
                    </div>
                </div>
            </div>
        </div>
        <div class="botC">
            <p class="px-3 pt-3 mb-0 small main_txt">{{$t('transaction.27')}}</p>
            <div class="container pt-3 history">
                <div class="history_box">
                    <ul class="pl-0">
                        <li class="d-flex align-items-center mb-3" v-for="(item, index) in history" :key="index">
                            <span class="txt_icon" v-if="item.type == 'D'">{{$t('transaction.47')}}</span>
                            <span class="txt_icon" v-if="item.type == 'W'">{{$t('transaction.48')}}</span>
                            <div class="left">
                                <p class="addr">{{item.from_address}}</p>
                                <p class="date pale_txt">{{item.create_time}}</p>
                            </div>
                            <div class="right mb-0">
                                <p class="main_txt mr-0 mb-0">{{typeName(item.type)}}{{item.amount}} </p><small>{{item.symbol}}</small>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="fixed_bottom_box p-3 d-flex">
                <button class="btn btn-block btn-primary mt-0" @click="sendCheck"><small>{{$t("coin.9")}}</small></button>
            </div>
        </div>
        
        <!-- 주소록 -->
        <div class="infoPp" v-if="infoPp">
            <div class="dimm" @click="infoPpOff()"></div>
            <div class="content">
                <h6 class="main_txt">{{token}} {{$t('transaction.49')}}</h6>
                
                <div class="form-group mt-3 mb-3">
                    <div class="l_btn">
                        <input type="text" class="form-control" :placeholder="$t('transaction.50')" @keyup.enter="searchAddress()" v-model="search">
                        <button type="button" class="btn btn-default pr-0" @click="searchAddress"><i class="far fa-search"></i></button>
                    </div>
                </div>

                <div class="scroll_box">
                    <ul class="addr_list">
                        <li v-for="(item,index) in addr_list" :key="index" class="my-2 py-3" @click="addressIn(item)">
                            <div class="addr_name mb-1"> {{item.title}}</div>
                            <div class="flex align-items-center">
                                <span class="badge badge-gray font-weight-normal mr-2">{{item.type}}</span>
                                <div class="addr_txt main_txt">{{item.address}}</div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'
export default {
    components: {
        QrcodeStream
    },
    data(){
        return{
            title:this.$t("coin.9"),
            net: this.$route.params.net,
            token: this.$route.params.token,
            prevP: false,
            pageD: false,
            coin_list:[],
            addr_list: [],
            address: '',
            selected_addr: null,
            selected_coin: null,
            selected_coin_num: 0,
            
            infoPp: false,
            search: '',

            history: [],

            camera: 'auto',
            camera_area : false,
        }
    },
    mounted(){        
        let recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute('src', '/assets/js/camera.js')
        document.head.appendChild(recaptchaScript)

        this.$store.commit("chgTitle",this.token + " "+this.title);
        this.$store.commit("backPage",this.prevP);
        this.$store.commit("backDashboard",this.pageD);
                
        this.coin_list.push(this.token);
        this.selected_coin = this.coin_list[0];
        
        const login = this.$store.state.login;
        if(login){
            this.Token_Check();
        }
    },
    methods:{    
        Token_Check: function(){
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/token/check`, {}).then(
            res => {
                if (res.status == 200) {
                    const code = res.data.code;
                    if(code == '200'){     
                        this.GetSync();
                        this.withdrawAddress();
                        this.TokenHistory();
                        this.GetWalletInfoToken();
                        this.checkOTP()
                    }else if(code == '9999'){
                        this.$alert(this.$t('signout.1')).then(()=>{
                            this.$store.dispatch('SETLOGOUT').then(()=>{
                                this.$router.push({name:'Signin'});
                            });
                        })
                    }
                }
            })
        },  
        checkOTP(){            
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/common/otp`,{}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        if(res.data.cnt ==0){
                            this.$alert(this.$t('security.17')).then(
                                ()=>{
                                    this.$router.push({name:'Security'});
                                }
                            )
                        }
                        

                    }
                }
            })
        },
        GetWalletInfoToken(){
            const symbol = this.token;
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/wallet_info_balance`, {symbol}).then(
            res => {
                if (res.status == 200) {
                    if (res.data.code == '200') {
                        this.selected_coin_num = res.data.balance;
                        // this.selected_coin_num = "1000";
                    }
                }
            })
        },
        TokenHistory(){
            const symbol = this.token;
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/transaction/list/each`,{symbol}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        this.history = res.data.list;
                    }
                }
            })
        },
        typeName(type){
            if(type == "D") return "+"
            else return "-"
        },
        sendCheck(){
            this.address = $("#address_to").val();
            const amount = this.selected_coin_num;
            if(amount == 0){
                this.$alert(this.$t('transaction.51')+this.token+this.$t('transaction.52'));
                return;
            }else if(this.address == ""){
                this.$alert(this.$t('transaction.53'));
                return;
            }
            else{
                this.isAddress();
            }
        },
        isAddress(){
            const net = this.net;
            const token = this.token;
            const address = this.address;
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/isAddress`,{net, address}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        const info = res.data.info;
                        if(info) this.$router.push(`/${this.$i18n.locale}/page/withdrawal/send/${net}/${token}/${address}`);
                        else this.$alert(this.$t('transaction.54'));
                    }
                }
            })
        },
        withdrawAddress : function(){
            const api = this.$store.state.baseURL;
            const symbol = this.token;

			this.$http.post(`${api}/member/wallet/transfer/address/each`,{symbol}).then(
			res => {
				if(res.status==200){
					if(res.data.code=='200'){
                        this.addr_list = res.data.list;
					}
				}
			})
        },    
        searchAddress: function() {
            const api = this.$store.state.baseURL;
            const symbol = this.token;
            const text = this.search;
            
			this.$http.post(`${api}/member/wallet/transfer/address/each/search`,{symbol, text}).then(
			res => {
				if(res.status==200){
					if(res.data.code=='200'){
                        
                        this.addr_list = res.data.list;
					}
				}
			})
        },
        addressIn(item){
            $("#address_to").val(item.address);
            this.address = item.address;
            this.infoPpOff();
        },
        GetSync(){
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/sync`,{})
        },
        infoPpOn: function(){
            this.infoPp = true;
        },
        infoPpOff: function(){
            this.infoPp = false;
        },
            
        onDecode (result) {
            $("#address_to").val(result);
            this.address = result;
            this.turnCameraOff()
            this.camera_area = false;
        },
        CancelQr: function() {
            this.turnCameraOff()
            this.camera_area = false;
        },
        runCamera: function(){    
            this.turnCameraOn();       
            this.camera_area = true;
        },   
        turnCameraOn () {
            this.camera = 'auto'
        },
        turnCameraOff () {
            this.camera = 'off'
        }
    },
    filters: {
        currency: function(value){
            var num = new Number(value);
            return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
        }
    },
    computed:{
        first(){
            return 0
        }
    }
}
</script>

<style scoped>
.container{
    background-color: transparent;
}
.form-control{
    padding-right: 40px;
}
option{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
select{
    background: none;
}
.has_fixed_bottom_box .container{
    /* min-height: auto; */
}
.fixed_bottom_box{
    background-color: #fff;
}

.form-group.line{
    border-bottom: none;
}

.history{
    /* height: calc(100vh - 191px - 50px - 54px - 19px); */
    min-height: calc(100vh - 270px);
    /* height: calc(100vh - 0px - 50px - 54px - 19px); */
    height: calc(100vh - 270px);
    overflow-y: auto;
}
.botC{
    background-color: #fff;
    border-radius: 25px 25px 0 0;
}
.detail_date{
    color: #999;
}
.txt_icon{
    display: block;
    width: 30px;
    height: 30px;
    background-color: #4B67F9;
    color: #fff;
    text-align: center;
    flex-shrink: 0;
    border-radius: 100%;
    font-size: 12px;
    line-height: 30px;
    margin-right: 10px;
}

.history_box{
    font-size: 14px;
}
.history_box p{
    margin-bottom: 0;
}
.history_box .left{
    width: 60%;
}
.history_box .right{
    width: calc(50%);
    text-align: right;
    font-weight: 500;
    margin-bottom: 10px;
}
.history_box .addr{
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
}
.history_box .date{
    font-size: 12px;
}

.form-group.line{
    margin-bottom: 10px;
}
.form-group.line input{
    border-bottom: 0;
    font-size: 14px;
    margin-bottom: 0;
}
.wallet{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.wallet h4{
    text-transform: uppercase;
}
.disable-white:disabled{
    background-color: #f6f6f6 !important;
    opacity: 1;
}
.form-group .r_btn input+button{
    padding: 0;
    text-align: center;
}

.form-group .r_btn input+button>div{
    width: 30px;
    height: 36px;
    line-height: 36px;
    position: relative;
}
.form-group .r_btn input+button>div:first-child::after{
    content: "";
    display: inline-block;
    width: 1px;
    background-color: #ddd;
    height: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
}


.infoPp .content{
    overflow: hidden;
}
.infoPp .form-group .l_btn input+button{
    width: 40px;
}
.infoPp .form-group .l_btn input{
    padding-left: 40px;
}
.infoPp .scroll_box{
    max-height: calc(75vh - 118px);
    display: block;
    overflow: auto;
}

.addr_list li{
    /* background-color: #fbfcfd; */
    background-color:#f9f9f9;
    border-radius: 4px;
    padding: 14px;
}
.addr_list .addr_name{
    font-size: 15px;
    font-weight: 500;
}
.addr_list .addr_txt{
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

</style>