<template>
    <div id="Trade"  class="has_fixed_bottom_box">
        <vue-element-loading :active="save" spinner="bar-fade-scale"/>
        <div class="step01" v-if="step = 1">
            <div class="no_radius p-3">
                
                <div class="form_box rounded shadow-custom">
                    <div class="px-3 py-3">
                        <!-- send coin (send) -->
                        <div class="form-group py-3 line d-flex flex-column mb-0">
                            <label class="d-flex justify-content-between">
                                <small class="pale_txt">{{send.symbol}} {{$t("package.2")}}</small>
                                <small class="pale_txt">Send</small>
                            </label>
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="d-flex align-items-center">
                                    <img :src="`../${send.img}`" height="45" class="mr-2"> 
                                    <div class="d-flex flex-column">
                                        <select class="form-control" v-model="send" @change="SelectSendCoin">
                                            <option :value="item" v-for="(item,index) in coin_list" :key="index">{{item.symbol}}</option>
                                        </select>
                                        <small class="overflow-x">{{send_balance}}</small>
                                    </div>
                                </div>
                                <i class="far fa-chevron-right mx-3"></i>
                                <input type="text" ref="set" class="form-control border text-right mb-4" placeholder="0" v-model="send_value" @change="SendInput">
                            </div>
                        </div>
                        <!-- 경계선 -->
                        <div class="chg_summary_box d-flex align-items-center">
                            <i class="far fa-sort-alt pale_txt"></i>
                            <div class="hr"></div>
                            <div class="txt_box">1 {{send.symbol}} = {{trade_rate}} {{selected.symbol}}</div>
                        </div>
                        <!-- selected coin (receive) -->
                        <div class="form-group py-3 line d-flex flex-column mb-0">
                            <label class="d-flex justify-content-between">
                                <small class="pale_txt">{{selected.symbol}} {{$t("package.2")}}</small>
                                <small class="pale_txt">Receive</small>
                            </label>
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="d-flex align-items-center">
                                    <img :src="`../${selected.img}`" height="45" class="mr-2"> 
                                    <div class="d-flex flex-column">
                                        <select class="form-control" v-model="selected" @change="SelectReceiveCoin">
                                            <option :value="item" v-for="(item,index) in swap_coin_list" :key="index">{{item.symbol}}</option>
                                        </select>
                                        <small class="overflow-x">{{selected_balance}}</small>
                                    </div>
                                </div>
                                <i class="far fa-chevron-right mx-3"></i>
                                <input type="text" class="form-control border text-right mb-4" v-model="selected_value" disabled>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="trade_box mt-4">
                    <!-- <div class="d-flex justify-content-around p-2 mb-3 bg-white shadow-custom rounded">
                        
                        <input hidden v-model="trade_per" type="radio" id="per-0" value="0" @change="tradePerChange">
                        <label class="btn btn-default" for="per-0">0%</label>
                        <input hidden v-model="trade_per" type="radio" id="per-25" value="25" @change="tradePerChange">
                        <label class="btn btn-default" for="per-25">25%</label>
                        <input hidden v-model="trade_per" type="radio" id="per-50" value="50" @change="tradePerChange">
                        <label class="btn btn-default" for="per-50">50%</label>
                        <input hidden v-model="trade_per" type="radio" id="per-100" value="100" @change="tradePerChange">
                        <label class="btn btn-default" for="per-100">100%</label>
                    </div> -->
                </div>
                
                <div class="form-group inline rounded shadow-custom p-3 bg-white line mb-0">
                    <label>{{$t('transaction.6')}}({{send.symbol}})</label>
                    <input type="text" class="form-control text-right" :value="fee_price" disabled>
                </div>
            </div>

            <div class="fixed_bottom_box p-2 d-flex">
                <button class="btn btn-block btn-primary" @click="AuthCheck()" :disabled="confirm"><small>{{$t('transaction.7')}}</small></button>
            </div>
            
            <div class="infoPp" v-if="infoChk">
                <div class="dimm" @click="infoChkOff()"></div>
                <div class="content pt-4">
                    <div class="form_box pt-2">
                        <div class="form-group line">
                            <div class="d-flex align-items-center justify-content-between">
                            </div>
                            <div class="r_btn">
                                <label>{{$t('transaction.8')}}[{{send.symbol}}]: {{send_value}}</label>
                                <input type="text" class="form-control mt-2" :value="`${$t('transaction.9')} ${selected.symbol}: ${selected_value}`" style="padding-right: 5px;" disabled>
                            </div>
                        </div>
                    </div>
                    <button type="button" class="btn btn-block btn-primary mb-2" @click="securityChk()"><small>{{$t('transaction.10')}}</small></button>
                </div>
            </div>

            <div class="infoPp" v-if="infoPp">
                <div class="dimm" @click="infoPpOff()"></div>
                <div class="content pt-4">
                    <div class="form_box pt-2" v-if="emailArea">
                        <div class="form-group line">
                            <div class="d-flex align-items-center justify-content-between">
                                <label>{{$t('transaction.11')}}</label>
                            </div>
                            <div class="r_btn">
                                <input type="password" class="form-control" v-model="email_code" :placeholder="$t('transaction.12')" :disabled="email_chk">
                                <button type="button" class="btn text-right pr-0" :disabled="email_chk" @click="EmailCodeCheck">{{$t('transaction.13')}}</button>
                            </div>
                        </div>
                    </div>
                    <div class="form_box pt-2" v-if="otpArea">
                        <div class="form-group line">
                            <label>OTP {{$t('transaction.13')}}</label>
                            <div class="r_btn">
                                <input type="password" class="form-control" v-model="otp_code" :placeholder="`OTP ${$t('transaction.14')}`" :disabled="otp_chk">
                                <button type="button" class="btn text-right pr-0" :disabled="otp_chk" @click="OTPCodeCheck">{{$t('transaction.13')}}</button>
                            </div>
                        </div>
                    </div>
                    <button type="button" class="btn btn-block btn-primary mb-2" @click="securityChk()" :disabled="!auth_chk"><small>{{$t('transaction.15')}}</small></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const exactMath = require('exact-math');

export default {
    data(){
        return{
            title:this.$t("menu.11"),
            save: false,
            prevP:false,
            pageD: false,
            
            coin_list: [],
            swap_coin_list: [],

            send: {},
            send_gas: 0,
            send_balance: 0,
            send_value: '',

            selected: {},
            selected_gas: 0,
            selected_balance: 0,
            selected_value: '',

            rate: [],

            unselect_coin: null,

            selected_rate: 0,
            send_rate: 0,
            trade_rate: 0,

            Gas : {},
            fee_price: 0,

            setAmount: '',
            getAmount: 0,
            trade_per:0,

            confirm: true,
            
            emailArea:false,
            email_code : '',
            email_chk:false,
            otpArea : false,
            otp_code :'',
            otp_chk:false,
            auth_chk: false,
            
            info: {},
            infoChk: false,
            infoPp: false,
        }
    },
    mounted(){
        this.$store.commit("chgTitle",this.title);
        this.$store.commit("backPage",this.prevP)
        this.$store.commit("backDashboard",this.pageD)

        this.Token_Check();
        if(this.$store.state.login){
            this.GetSync();
            this.CoinRate();
            this.MySecurityInfo();
            // this.CoinInfo();
            // this.SelectCoin();
            // this.WalletInfo();
            // this.TradePer();
        }
    },
    methods:{
        Token_Check: function(){
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/token/check`, {}).then(
            res => {
                if (res.status == 200) {
                    const code = res.data.code;
                    if(code == '9999'){
                        this.$alert(this.$t('signout.1')).then(()=>{
                            this.$store.dispatch('SETLOGOUT').then(()=>{
                                this.$router.push({name:'Signin'});
                            });
                        })
                    }
                }
            })
        },  
        CoinRate() {
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/rate`, {}).then(
            res => {
                if (res.status == 200) {
                    if (res.data.code == '200') {
                        this.rate = res.data.rate;
                    }
                }
            }).finally(() => {
                this.CoinInfo();
            })
        },    
        async CoinInfo() {
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/coin_info`, {}).then(
            res => {
                if (res.status == 200) {
                    if (res.data.code == '200') {
                        this.coin_list = res.data.info;
                        this.send = res.data.info[0];
                        this.selected = res.data.info[1];           
                        this.SetCoinList();
                    }
                }
            }).finally(() => {     
                this.CoinBalanceData('send', this.send.symbol);
                this.CoinBalanceData('selected', this.selected.symbol);

                this.RateSet();                
                this.GasPrice();
            })
        },
        RateSet() {     
            for(var i=0; i<this.rate.length; i++){
                if(this.send.symbol == this.rate[i].symbol){
                    this.send_rate = this.rate[i].usd
                }
            }            
            for(var i=0; i<this.rate.length; i++){
                if(this.selected.symbol == this.rate[i].symbol){
                    this.selected_rate = this.rate[i].usd;
                }
            }

            this.trade_rate = exactMath.round(this.selected_rate/this.send_rate, -6);

        },
        SetCoinList() {
            this.swap_coin_list = [];
            for(const el of this.coin_list){
                if(el.symbol != this.send.symbol){
                    if(this.send.symbol == 'ETH'){
                        this.swap_coin_list.push(el)
                    }
                    else if(this.send.symbol == 'BNB'){
                        if(el.symbol != 'ETH'){
                            this.swap_coin_list.push(el)
                        }
                    }
                    else {
                        if(el.symbol != 'ETH' && el.symbol != 'BNB'){ 
                            this.swap_coin_list.push(el)
                        }
                    }
                }
            }
        },
        SelectSendCoin: function() {
            this.GasPrice();
            this.SetCoinList();

            this.send_value = '';
            this.selected_value = '';
            this.selected = this.swap_coin_list[0];
            this.selected_value = 0;

            this.CoinBalanceData('send', this.send.symbol);
            this.CoinBalanceData('selected', this.selected.symbol);
            this.RateSet();
        }, 
        SelectReceiveCoin() {
            this.send_value = '';
            this.selected_value = '';
            this.CoinBalanceData('send', this.send.symbol);
            this.CoinBalanceData('selected', this.selected.symbol);
            this.RateSet();
        },
        CoinBalanceData(type, symbol){
            this.save = true;
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/wallet_info_balance`, {symbol}).then(
            res => {
                if (res.status == 200) {
                    if (res.data.code == '200') {
                        this.$refs.set.focus();
                        if(type == "send"){
                            this.send_balance = exactMath.round(res.data.balance, -6);
                            this.send_gas = res.data.gas_balance;
                        } else {
                            this.selected_balance = exactMath.round(res.data.balance, -6);
                            this.selected_gas = res.data.gas_balance;
                        }
                    }
                }
            }).finally(() => {
                this.save = false;
            })
        },   
        GasPrice() {
            this.save = true;
            const api = this.$store.state.baseURL;
            const symbol = this.send.symbol;
            var amount = 0;
            if(this.send_value != '') amount = this.send_value;
            this.$http.post(`${api}/member/wallet/system/gasPrice`,{symbol, amount}).then(
            res => {
                if(res.status==200){
                    if(res.data.code == '200'){
                        this.Gas = res.data.fee;
                        this.fee_price =  exactMath.round(res.data.fee.fee, -6);
                    }
                }
            }).finally(() => {
                this.save = false;
            })
        },
        SendInput(){            
            var chk = 0;
            var fee = 0;
            if(this.send.symbol == this.send.net){
                fee = this.fee_price;
                chk = exactMath.add(this.send_value*1, fee*1);
            } else {
                fee = this.fee_price;
                chk = this.send_value;
            }

            if(exactMath.sub(this.send_balance, fee) < 0) {
                this.trade_per = 0;
                this.send_value = '';
                this.$alert(this.$t('transaction.44'));
            }
            else if(exactMath.sub(this.send_balance,chk) < 0){
                this.trade_per = 0;
                this.send_value = '';
                this.$alert(this.$t('transaction.17'));
            }
            else if(exactMath.round(this.send_value*this.trade_rate, -6) <= 0){
                this.trade_per = 0;
                this.$alert(this.$t('transaction.59'));
            }
            else {
                this.GasPrice();
                this.selected_value = exactMath.round(this.send_value*this.trade_rate, -6);
                this.confirm = false;
            }
        },
        tradePerChange: function() {
            if(this.trade_per == 0){
                this.send_value = '';
                this.selected_value = '';
                return;
            }

            const per = this.trade_per/100;
            if(this.send.symbol == this.send.net){
                var set_val = exactMath.add((this.send_balance * per),this.fee_price);
                if(this.send_balance - set_val >= 0) {
                    this.send_value = set_val;
                }
                else {
                    this.$alert(this.$t('transaction.44')).then(() => {
                        this.trade_per = 0;
                        this.send_value = '';
                        this.selected_value = '';
                    });
                }
            } else {
                this.send_value = this.send_balance * per
            }
            this.SendInput();
        },

        MySecurityInfo : function(){
            const api = this.$store.state.baseURL;
            
            this.$http.post(`${api}/member/setting/my_security_info`,{}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        if(res.data.info.trade_email =='Y'){
                            this.emailArea = true
                        }else {
                            this.emailArea = false
                            this.email_chk = true
                        }

                        if(res.data.info.trade_otp =='Y'){
                            this.otpArea = true
                        }else {
                            this.otpArea = false
                            this.otp_chk = true
                        }
                    }
                }
            })
		},
        AuthCheck: function(){           
            this.infoChk = true;
        },     
        securityChk: function() {    
            this.infoChk = false;        
            if(!this.email_chk || !this.otp_chk){
                this.infoPp = true;
                if(this.emailArea) this.EmailSecurity();
            }
            else {
                this.TradeCheck();
            }
        },
        TradeCheck: function() {
            const symbol = this.send.symbol;
            const value = this.send_value;
            const gas = this.Gas;

            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/to/system/send`,{symbol, value, gas}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        const hash = res.data.hash;
                        this.SaveSwapData(hash);
                    }
                    else {
                        this.$alert(this.$t('trade.1')).then(() => {
                            this.$router.go(0);
                        })
                    }
                }
            })
        },
        SaveSwapData(hash) {
            const symbol = this.selected.symbol;
            const value = this.selected_value;
            
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/swap/save`,{symbol, value, hash}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        this.$alert(this.$t('trade.2')).then(() => {
                            this.$router.go(0);
                        })
                    }
                    else {
                        this.$alert(this.$t('trade.3'));
                    }
                }
            })
        },
        EmailSecurity : function(){
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/common/email_security`,{}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        this.$alert(this.$t('transaction.20'));
                    }
                }else if(res.data.code == '9999'){
                    this.$alert(this.$t('signout.1')).then(()=>{
                        this.$store.dispatch('SETLOGOUT').then(()=>{
                            this.$router.push({name:'Signin'});
                        });
                    })
                }
            })
        },
        EmailCodeCheck : function(){
            const api = this.$store.state.baseURL;
            const code = this.email_code;
            
            this.$http.post(`${api}/member/common/email_code_check`,{code}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        this.$alert(this.$t('transaction.21')).then(
                        ()=>{
                            this.email_chk = true;
                            if(this.email_chk && this.otp_chk) this.auth_chk = true;
                        }
                    )
                    }else if(res.data.code=='100'){
                        this.$alert(this.$t('transaction.22'))
                    }
                }
            })
        },
        OTPCodeCheck : function(){
            const api = this.$store.state.baseURL;
            const code = this.otp_code;
            this.$http.post(`${api}/member/common/otp_code_check`,{code}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        this.$alert(this.$t('transaction.23')).then(
                        ()=>{
                            this.otp_chk = true;
                            if(this.email_chk && this.otp_chk) this.auth_chk = true;
                        })
                    }else if(res.data.code=='100'){
                        this.$alert(this.$t('transaction.24'));
                    }
                }

            })
        },
        infoPpOn: function(item){
            this.infoPp = true;
        },
        infoPpOff: function(){
            this.infoPp = false;
            this.AuthClear();
        },
        infoChkOff: function(){
            this.infoChk = false;
        },
        GetSync(){
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/sync`,{})
        },
        AuthClear: function() {            
            this.email_code = '';
            this.otp_code = '';
            this.email_chk=false;
            this.otp_chk=false;
            this.auth_chk = false;
            this.MySecurityInfo();
        },
        fotmatNum: function(value){
            return value.replace(/(^0+)/g, "");
        }
    },
    directives: {
        focus: {
            inserted: function (el) {
                el.focus()
            }
        }
    },
    filters: {
        currency: function(value){
            var num = new Number(value);
            return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
        }
    }
}
</script>

<style scoped>
#Trade{
    height: calc(100vh - 50px);
    overflow-y: auto;
}
.fixed_bottom_box{
    background-color: transparent;
}
.botC{
    background-color: #fff;
}
.detail_date{
    color: #999;
}

.botC .form-group.line{
    margin-bottom: 10px;
    border-bottom: none;
}
.botC .form-group.line input{
    border-bottom: 0;
    font-size: 14px;
    margin-bottom: 0;
}

.botC .form-group .r_btn input+button{
    color: #4B67F9;
}

.btn-default{
    border: 1px solid #ddd;
    width: 24%;
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    padding: 0;
}
input[type=radio]:checked+.btn-default{
    background-color: #4B67F9;
    border: none;
    color: #fff;
}
.chg_summary_box{
    padding: 12px 0;
}
.chg_summary_box .hr{
 border-bottom: 1px solid #eaeaea;
 width: 100%;
}
.chg_summary_box .txt_box{
    border: 1px solid #eaeaea;
    border-radius: 10px;
    font-size: 12px;
    min-width: 150px;
    text-align: center;
    flex-shrink: 0;
    padding: 0 10px;
}
.chg_summary_box i{
    font-size: 13px;
    padding-right: 12px;
}
.form_box{
    background-color: #fff;
}
select.form-control{
    min-width: 100px;    
    height: 30px;
    font-size: 12px;
}
.trade_box label{
    margin-bottom: 0;
}
.shadow-custom{
    box-shadow: 3px 3px 2px #eee;
}
.overflow-x{
    overflow-x: auto;
    width: 100px;
    padding-top: 2px;
}
.form-group.line input{
    height: 30px;
    border-radius: 4px;
}
</style>