<template>
    <div class="container">
        <div class="history_box">
            <ul class="pl-0">
                <li class="d-flex align-items-center mb-4">                            
                    <div class="left">
                        <p class="addr">{{$t('coin.4')}}</p>
                    </div>
                    <div class="right">
                        <p class="main_txt mr-0">{{$t('coin.3')}}</p>
                    </div>
                </li>
                <li class="d-flex align-items-center mb-4" v-for="(item, index) in history" :key="index" @click="infoPpOn(item)">                            
                    <span class="txt_icon" 
                        :class="{'send' : item.type == 'W', 
                        'receive' : item.type == 'D' }">

                        <i class="far fa-ellipsis-h" v-if="item.hash===null"></i>
                        <i class="far fa-check" v-else-if="item.hash!==null"></i>
                        <i class="far fa-times" v-else></i>

                    </span>

                    <div class="left">
                        <p class="addr" v-if="item.type == 'W'">{{typeName(item.type)}}</p>
                        <p class="addr" v-else>{{typeName(item.type)}}</p>
                        <p class="date pale_txt">{{item.create_time}}</p>
                    </div>
                    <div class="right">
                        <p>{{item.amount}} {{item.symbol}}</p>
                        <p class="main_txt mr-0">{{usdCalc(item.symbol, item.amount)}} $</p>
                    </div>
                </li>
            </ul>
        </div>
        <div class="infoPp" v-if="infoPp">
            <div class="dimm" @click="infoPpOff()"></div>
            <div class="content">
                <div class="form-group line">
                    <label>{{$t("coin.5")}}</label>
                    <input type="text" class="form-control" :value="typeName(selected_item.type)" disabled>
                </div>
                <div class="form-group line">
                    <label>{{$t("coin.19")}}</label>
                    <div class="r_btn">
                        <input type="text" class="form-control" :value="selected_item.from_address" disabled>
                        <button class="btn" @click="CopyWallet(selected_item.from_address)">
                            <i class="far fa-copy"></i>
                        </button>
                    </div>
                </div>
                <div class="form-group line">
                    <label>{{$t("coin.20")}}</label>
                    <div class="r_btn">
                        <input type="text" class="form-control" :value="selected_item.to_address" disabled>
                        <button class="btn" @click="CopyWallet(selected_item.to_address)">
                            <i class="far fa-copy"></i>
                        </button>
                    </div>
                </div>
                <div class="form-group line">
                    <label>{{$t("coin.3")}}</label>
                    <input type="text" class="form-control" :value="selected_item.amount+' '+selected_item.symbol" disabled>
                </div>
                <div class="form-group line">
                    <label>{{$t("coin.4")}}</label>
                    <input type="text" class="form-control" :value="selected_item.create_time" disabled>
                </div>
                <div class="form-group line" v-if="selected_item.hash !== null">
                    <label>Hash</label>
                    <div class="r_btn">
                        <input type="text" class="form-control" :value="selected_item.hash" disabled>
                        <button class="btn" @click="hashCheck(selected_item.net, selected_item.hash)">
                            <i class="far fa-share-square"></i>
                        </button>
                    </div>
                </div>
                <div class="form-group line">
                    <label>{{$t("coin.6")}}</label>
                    <input type="text" class="form-control" :value="statusName(selected_item.hash)" disabled>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            title:this.$t("menu.9"),
            prevP:true,
            pageD: false,
            rate: 0,
            infoPp: false,
            selected_item: null,
            history:[],
            rate: []
        }
    },
    mounted(){
        this.$store.commit("chgTitle",this.title)
        this.$store.commit("backPage",this.prevP)
        this.$store.commit("backDashboard",this.pageD)
        
        const login = this.$store.state.login;
        if(login){
            this.Token_Check();
        }
    },
    methods:{           
        Token_Check: function(){
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/token/check`, {}).then(
            res => {
                if (res.status == 200) {
                const code = res.data.code;
                if(code == '200'){   
                    this.TokenHistory();
                    this.CoinRate();
                }else if(code == '9999'){
                    this.$alert(this.$t('signout.1')).then(()=>{
                        this.$store.dispatch('SETLOGOUT').then(()=>{
                            this.$router.push({name:'Signin'});
                        });
                    })
                }
                }
            })
        },
        TokenHistory(){
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/transaction/list`,{}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        this.history = res.data.list;
                    }
                }
            })
        },        
        CoinRate() {
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/rate`, {}).then(
            res => {
                if (res.status == 200) {
                    if (res.data.code == '200') {
                        this.rate = res.data.rate;
                    }
                }
            })
        },
        usdCalc(symbol, value){
            const rate = this.rate;
            for(var i=0; i<rate.length; i++){
                if(symbol == rate[i].symbol) return (value * rate[i].usd).toFixed(6);
            } 
        },
        CopyWallet: function (addr) {
            this.$copyText(addr).then( 
            () =>{
                this.$fire({title:this.$t('mywallet.18'),html : addr});
            })
        },
        hashCheck: function(net, hash) {
            if(net === 'BTC') window.open('https://www.blockchain.com/btc/tx/'+hash, '_blank');
            else if(net === 'ETH') window.open('https://etherscan.io/tx/'+hash, '_blank');
            else if(net === 'BNB') window.open('https://bscscan.com/tx/'+hash, '_blank');
            else if(net === 'AVAX') window.open('https://snowtrace.io/tx/'+hash, '_blank');
        },
        typeName: function(val){
            if(val == 'W') return this.$t("coin.9")
            else if(val == 'D') return this.$t("coin.8")
        },
        statusName: function(val){
            if(val == null) return this.$t("coin.14")
            else if(val != null) return this.$t("coin.13")
            else return this.$t("coin.15")
        },
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        showAlert: (text) => {
            alert(text)
        },        
        infoPpOn: function(item){
            this.selected_item = item;
            this.infoPp = true;
        },
        infoPpOff: function(){
            this.infoPp = false;
        },
        transStatus: function(x){
            if( x == "complete" ){
                return this.$t("coin.13")
            }else if( x == "cancel" ){
                return this.$t("coin.15")
            }else if( x == "wait" ){
                return this.$t("coin.14")
            }
        }
    },
    directives: {
        focus: {
            inserted: function (el) {
                el.focus()
            }
        }
    }
}
</script>

<style scoped>
    .form-group.line{
        margin-bottom: 10px;
    }
    .form-group.line input{
        border-bottom: 0;
        font-size: 14px;
        margin-bottom: 0;
    }
.history{
    height: calc(100vh - 106px - 85px - 54px);
    overflow-y: auto;
}
.botC{
    background-color: #fff;
}
.detail_date{
    color: #999;
}
.history_box{
    font-size: 14px;
}
.history_box p{
    margin-bottom: 0;
}
.history_box .left{
    width: 50%;
}
.history_box .right{
    width: calc(50%);
    text-align: right;
    font-weight: 500;
}
.history_box .addr{
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
}
.history_box .date{
    font-size: 12px;
}

.form-group.line{
    margin-bottom: 10px;
}
.form-group.line input{
    border-bottom: 0;
    font-size: 14px;
    margin-bottom: 0;
}
.wallet{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.wallet h4{
    text-transform: uppercase;
}
input{
    font-size: 14px;
}
</style>