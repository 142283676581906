<template>
    <div id="WithdrawalDetail" class="has_fixed_bottom_box">
        <div class="container">
            <vue-element-loading :active="save" spinner="bar-fade-scale"/>
            <div>
                <div class="form_box" @click="focusAmount">
                    <div class="form-group line mb-4">
                        <label class="font-weight-bold">{{$t('transaction.30')}}</label>
                        <input type="text" class="form-control" :value="address" style="color: #72a4ff" disabled>
                    </div>
                    <div class="px-2" v-if="tokenCHK">
                        <div class="form-group inline mb-0 border-bottom-0">
                            <label class="mr-2">{{$t('transaction.34')}}({{net}})</label>
                            <input type="text" class="form-control text-right" v-model="gasBAL" disabled>
                        </div>
                    </div>
                    <div class="px-2">
                        <div class="form-group inline mb-0 border-bottom-0">
                            <label class="mr-2">{{$t('transaction.34')}}({{token}})</label>
                            <input type="text" class="form-control text-right" v-model="assets" disabled>
                        </div>
                    </div>
                    <div class="px-2" v-if="lock > 0">
                        <div class="form-group inline mb-0 border-bottom-0">
                            <label class="mr-2">LOCK ({{token}})</label>
                            <input type="text" class="form-control text-right" v-model="lock" disabled>
                        </div>
                    </div>
                    <div class="form-group border-line mt-3">
                        <div class="l_btn w-100">
                            <input type="number" class="form-control text-right" ref="amount" placeholder="0" v-model="amount" @change="transBalanceChk" v-focus @keyup="transBalanceChk">
                            <button class="btn font-weight-bold pr-0">{{$t('transaction.36')}}</button>
                        </div>
                    </div>
                    <div class="receipt mt-5">
                        <div class="form-group inline mb-0 border-bottom-0">
                            <label class="mr-2">{{$t('transaction.55')}}({{net}})</label>
                            <input type="text" class="form-control text-right" :value="gas_price" disabled>
                        </div>
                        <div class="form-group inline mb-0 border-bottom-0 total_amount">
                            <label for="" class="main_txt font-weight-bold mr-2">{{$t('transaction.38')}}</label>
                            <input type="text" class="form-control pb-0 text-right font-weight-bold" :value="total" disabled>
                        </div>
                    </div>
                </div>
                <div class="fixed_bottom_box p-3 d-flex">
                    <button @click="AuthCheck()" class="btn btn-block btn-primary mt-0" :disabled="!confirm_chk"><small>{{$t('transaction.39')}}</small></button>
                </div>

                
                <div class="infoPp" v-if="infoPp">
                    <div class="dimm" @click="infoPpOff()"></div>
                    <div class="content pt-4">
                        <div class="form_box pt-2" v-if="emailArea">
                            <div class="form-group line">
                                <div class="d-flex align-items-center justify-content-between">
                                    <label>{{$t('transaction.11')}}</label>
                                </div>
                                <div class="r_btn">
                                    <input type="password" class="form-control" v-model="email_code" :placeholder="$t('transaction.12')" :disabled="email_chk">
                                    <button type="button" class="btn btn-default text-right pr-0" :disabled="email_chk" @click="EmailCodeCheck">{{$t('transaction.13')}}</button>
                                </div>
                            </div>
                        </div>
                        <div class="form_box pt-2" v-if="otpArea">
                            <div class="form-group line">
                                <label>OTP {{$t('transaction.13')}}</label>
                                <div class="r_btn">
                                    <input type="password" class="form-control" v-model="otp_code" :placeholder="`OTP ${$t('transaction.14')}`" :disabled="otp_chk">
                                    <button type="button" class="btn btn-default text-right pr-0" :disabled="otp_chk" @click="OTPCodeCheck">{{$t('transaction.13')}}</button>
                                </div>
                            </div>
                        </div>
                        <button type="button" class="btn btn-block btn-primary mb-2" :disabled="!auth_chk" @click="FinallyChk"><small>{{$t('transaction.15')}}</small></button>
                    </div>
                </div>
            </div>

        </div>
        
    </div>
</template>

<script>
const exactMath = require('exact-math');
export default {
    data(){
        return{
            title:this.$t("coin.9"),
            prevP: false,
            pageD: false,
            save: false,
            amount: '',
            
            net: this.$route.params.net,
            token: this.$route.params.token,
            address: this.$route.params.address,
            Gas: {},
            tokenCHK : false,
            gasBAL:0,
            assets:0,
            lock: 0,
            gas_price:0,
            total: 0,
            
            confirm_chk: false,
            email_code : '',
            emailArea:false,
            email_chk:false,
            
            otp_code :'',
            otpArea : false,
            otp_chk:false,
            pin_chk:false,

            auth_chk: false,    
            infoPp: false,
        }
    },
    mounted(){
        this.$store.commit("chgTitle",this.token + " " +this.title)
        this.$store.commit("backPage",this.prevP)
        this.$store.commit("backDashboard",this.pageD)
        
        const login = this.$store.state.login;
        if(login){            
            this.GetWalletInfoToken();
            this.MySecurityInfo();
            this.GetWalletLock();
        }
    },
    methods:{        
        Token_Check: function(){
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/token/check`, {}).then(
            res => {
                if (res.status == 200) {
                    const code = res.data.code;
                    if(code == '9999'){
                        this.$alert(this.$t('signout.1')).then(()=>{
                            this.$store.dispatch('SETLOGOUT').then(()=>{
                                this.$router.push({name:'Signin'});
                            });
                        })
                    }
                }
            })
        },
        GetWalletInfoToken(){
            this.save = true;
            const symbol = this.token;
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/wallet_info_balance`, {symbol}).then(
            res => {
                if (res.status == 200) {
                    if (res.data.code == '200') {
                        const balance = res.data.balance;
                        this.assets = balance;

                        if(symbol != this.net) {
                            this.tokenCHK = true;
                            this.gasBAL = res.data.gas_balance;
                        }
                    }
                }
            }).finally(() => {
                this.save =false;
                this.Token_Check();
            })
        }, 
        MySecurityInfo : function(){
            const api = this.$store.state.baseURL;
            
            this.$http.post(`${api}/member/setting/my_security_info`,{}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        const info = res.data.info;
                        if(info.send_email =='Y'){
                            this.emailArea = true
                        }else {
                            this.emailArea = false
                            this.email_chk = true
                        }

                        if(info.send_otp =='Y'){
                            this.otpArea = true
                        }else {
                            this.otpArea = false
                            this.otp_chk = true
                        }

                        if(info.send_pin == "Y"){
                            this.pin_chk = true;
                        }
                    }
                }
            })
        },
        transBalanceChk() {
            this.confirm_chk = false;
            // if(this.amount == 0 || this.amount == ''){
            //     this.amount = '';
            //     return this.$alert(this.$t('transaction.58'));
            // }
            
            const total_amount = exactMath.add(this.amount, this.lock);
            const assets = parseFloat(this.assets);
            const amount = parseFloat(total_amount);

            if(assets <= amount){
                this.amount = '';
                this.gas_price = 0;
                this.total = 0;
                this.$alert(this.$t('transaction.17'));
            }
            else {
                this.GasPrice();
            }
        },
        GasPrice() {
            this.save = true;
            const api = this.$store.state.baseURL;
            const symbol = this.token;
            const to_address = this.address;
            const amount = this.amount;
            this.$http.post(`${api}/member/wallet/gasPrice`,{symbol, to_address, amount}).then(
            res => {
                if(res.status==200){
                    if(res.data.code == '200'){
                        this.Gas = res.data.fee;
                        var chk = 0;
                        var fee = 0;
                        if(symbol == this.net){
                            fee = res.data.fee.fee;
                            chk = exactMath.add(this.amount, fee);
                        } else {
                            fee = res.data.fee.fee;
                            chk = this.amount;
                        }

                        if(exactMath.sub(this.assets,fee) < 0) {
                            this.amount = '';
                            this.$alert(this.$t('transaction.44'));
                        }
                        else if(exactMath.sub(this.assets,chk) < 0){
                            this.$alert(this.$t('transaction.17'));
                        }
                        else {
                            this.gas_price = exactMath.round(fee, -6);
                            this.total = exactMath.round(chk, -6);
                            this.confirm_chk = true;
                        }
                    }
                }
            }).finally(() => {
                this.save = false;
            })
        },
        GetWalletLock() {
            const symbol = this.token;
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/lock/check`, {symbol}).then(
            res => {
                if (res.status == 200) {
                    if (res.data.code == '200') {
                        this.lock = res.data.lock;
                    }
                }
            })
        },
        AuthCheck: function(){  
            if(this.emailArea || this.otpArea){
                this.infoPp = true;
                if(this.emailArea) this.EmailSecurity();
            }
            else if(this.pin_chk){
                this.$router.push('/'+this.$i18n.locale+'/page/pwinput/'+this.token+'/'+this.amount+'/'+this.address);
            }
            else{
                this.WithdrawSend();
            }
        },        
        EmailSecurity : function(){
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/common/email_security`,{}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        this.$alert(this.$t('transaction.20'));
                    }
                }
            })
        },
        EmailCodeCheck : function(){
            const api = this.$store.state.baseURL;
            const code = this.email_code;
            
            this.$http.post(`${api}/member/common/email_code_check`,{code}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        this.$alert(this.$t('transaction.21')).then(
                        ()=>{
                            this.email_chk = true;
                            if(this.email_chk && this.otp_chk) this.auth_chk = true;
                        }
                    )
                    }else if(res.data.code=='100'){
                        this.$alert(this.$t('transaction.22'))
                    }
                }
            })
        },
        OTPCodeCheck : function(){
            const api = this.$store.state.baseURL;
            const code = this.otp_code;
            this.$http.post(`${api}/member/common/otp_code_check`,{code}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        this.$alert(this.$t('transaction.23')).then(
                        ()=>{
                            this.otp_chk = true;
                            if(this.email_chk && this.otp_chk) this.auth_chk = true;
                        })
                    }else if(res.data.code=='100'){
                        this.$alert(this.$t('transaction.24'));
                    }
                }

            })
        },
        FinallyChk() {
            if(!this.email_chk) this.$alert(this.$t('transaction.56'))
            else if(!this.otp_chk) this.$alert(this.$t('transaction.57'));
            else if(this.pin_chk) this.$router.push('/'+this.$i18n.locale+'/page/pwinput/'+this.token+'/'+this.amount+'/'+this.address);
            else this.WithdrawSend();
        },        
        WithdrawSend : function(){
            const symbol = this.token;
            const value = this.amount;
            const to = this.address;
            const gas = this.Gas;
            if(symbol =='BISKIT'){
                const check_value = exactMath.sub(value,500);

                if(check_value < 0){
                    this.$alert(this.$t('transaction.16'));
                    return false;
                }
            }
            const api = this.$store.state.baseURL;            
            this.save = true;
            this.$http.post(`${api}/member/wallet/send`,{symbol,value,to,gas}).then(
                res => {
                    if(res.status==200){
                        if(res.data.code=='200'){
                            this.$alert(this.$t('transaction.42')).then(
                            ()=>{
                                this.$router.push('/'+this.$i18n.locale+'/page/transaction-detail');
                            })
                        }else if(res.data.code=='120'){
                            this.$alert(this.$t('transaction.43'));
                        }else if(res.data.code=='100'){
                            this.$alert(this.$t('transaction.60')).then(() => {
                                this.$router.push('/'+this.$i18n.locale+'/page/withdrawals/'+this.net+"/"+symbol);
                            });
                        }
                    }
            }).finally(() => {
                this.save = false;
                this.Token_Check();
            })
        },
        focusAmount: function() {
            this.$refs.amount.focus();
        },
        infoPpOff: function(){
            this.email_code = '';
            this.otp_code = '';
            this.email_chk = false;
            this.otp_chk = false;
            this.auth_chk = false;
            this.infoPp = false;
            this.MySecurityInfo();
        },
    },
    directives: {
        focus: {
            inserted: function (el) {
                el.focus()
            }
        }
    }
}
</script>

<style scoped>
.history{
    height: calc(100vh - 106px - 85px - 54px);
    overflow-y: auto;
}
.botC{
    background-color: #fff;
}
.detail_date{
    color: #999;
}
.txt_icon{
    display: block;
    width: 30px;
    height: 30px;
    background-color: #4B67F9;
    color: #fff;
    text-align: center;
    flex-shrink: 0;
    border-radius: 100%;
    font-size: 12px;
    line-height: 30px;
    margin-right: 10px;
}

.history_box{
    font-size: 14px;
}
.history_box p{
    margin-bottom: 0;
}
.history_box .left{
    width: 50%;
}
.history_box .right{
    width: calc(50%);
    text-align: right;
    font-weight: 500;
}
.history_box .addr{
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
}
.history_box .date{
    font-size: 12px;
}

.botC .form-group.line{
    margin-bottom: 10px;
}
.botC .form-group.line input{
    border-bottom: 0;
    font-size: 14px;
    margin-bottom: 0;
}
.wallet{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.wallet h4{
    text-transform: uppercase;
}
input{
    /* font-size: 14px; */
    font-size: 0.9em;
}
.form-group.border-line .l_btn input{
    border: 2px solid #4B67F9;
    font-size: 1.2em;
}
.receipt{
    /* background-color: #fff; */
    background-color: #fbfbfb;
    /* padding: 10px 15px; */
    padding: 18px;
    /* border-top: 1px dashed #ddd; */
    /* border-radius: 15px; */
    border-top: 2px solid #4B67F9;
    border-radius: 0;
}
.form-group .l_btn input+button{
    width: 100px;
    font-size: 0.9em;
}
.form-group .l_btn input{
    padding-left: 100px;
}
.form-group.line label, .form-group.inline label{
    font-size: 0.9em;
}
.form-group.border-line .form-control{
    height: 50px;
}
.total_amount{
    border-top: 1px dashed #ddd;
    padding-top: 10px;
    margin-top: 15px;
}
.total_amount input{
    font-size: 1.2em;
}
</style>