<template>
    <div id="PwInput"  class="has_fixed_bottom_box">
        <div class="tr_pw_box">
            <vue-element-loading :active="save" spinner="bar-fade-scale"/>
            <div class="container">
                <p class="tit text-center mt-5 font-weight-bold mb-4">
                    {{$t("pwinput.1")}}
                </p>
                <div class="form-group border-line mb-2">
                    <input type="password" ref="trpw" id="tr_pw" class="form-control text-center" pattern="[0-9]*" maxlength="8" v-model="transaction_pw" v-focus @keyup.enter="PincodeCheck()">
                </div>
            </div>
            <div class="fixed_bottom_box p-3 d-flex">
                <button class="btn btn-block btn-primary" v-if="confirm_chk" @click="PincodeCheck"><small>{{$t("pwinput.2")}}</small></button>
                <button class="btn btn-block btn-primary" v-else @click="AuthCheck"><small>{{$t("pwinput.3")}}</small></button>
            </div>
            <div class="infoPp" v-if="infoPp">
                <div class="dimm" @click="infoPpOff()"></div>
                <div class="content pt-4">
                    <div class="form_box pt-2" v-if="emailArea">
                        <div class="form-group line">
                            <div class="d-flex align-items-center justify-content-between">
                                <label style="text-align: left !important;">{{$t('transaction.11')}}</label>
                            </div>
                            <div class="r_btn">
                                <input type="password" class="form-control" v-model="email_code" :placeholder="$t('transaction.12')" :disabled="email_chk">
                                <button type="button" class="btn btn-default text-right pr-0" :disabled="email_chk" @click="EmailCodeCheck" style="text-align: center !important;">{{$t('transaction.13')}}</button>
                            </div>
                        </div>
                    </div>
                    <div class="form_box pt-2" v-if="otpArea">
                        <div class="form-group line">
                            <label style="text-align: left !important;">OTP {{$t('transaction.13')}}</label>
                            <div class="r_btn">
                                <input type="password" class="form-control" v-model="otp_code" :placeholder="`OTP ${$t('transaction.14')}`" :disabled="otp_chk">
                                <button type="button" class="btn btn-default text-right pr-0" :disabled="otp_chk" @click="OTPCodeCheck" style="text-align: center !important;">{{$t('transaction.13')}}</button>
                            </div>
                        </div>
                    </div>
                    <button type="button" class="btn btn-block btn-primary mb-2" @click="FinallyChk"><small>{{$t('transaction.15')}}</small></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            title:this.$t("pwinput.1"),
            prevP:false,
            pageD: false,
            save: false,
            transaction_pw: '',
            Gas: {},
            token: this.$route.params.token,
            amount: this.$route.params.amount,
            address: this.$route.params.address,
            
            confirm_chk: false,
            email_code : '',
            emailArea:false,
            email_chk:false,
            
            otp_code :'',
            otpArea : false,
            otp_chk:false,

            auth_chk: false,    
            infoPp: false,
        }
    },
    mounted(){
        this.$store.commit("chgTitle",this.title);
        this.$store.commit("backPage",this.prevP)
        this.$store.commit("backDashboard",this.pageD)
        
        const login = this.$store.state.login;
        if(login){
            this.GasPrice();
            this.MySecurityInfo();
        }
    },
    methods:{
        showAlert: (text) => {
            alert(text)
        },        
        GasPrice() {
            this.save = true;
            const api = this.$store.state.baseURL;
            const symbol = this.token;
            const to_address = this.address;
            const amount = this.amount;
            this.$http.post(`${api}/member/wallet/gasPrice`,{symbol, to_address, amount}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        this.Gas = res.data.fee;
                    }
                }else if(res.data.code == '9999'){
                    this.$alert(this.$t('signout.1')).then(()=>{
                        this.$store.dispatch('SETLOGOUT').then(()=>{
                            this.$router.push({name:'Signin'});
                        });
                    })
                }	
            }).finally(() => {
                this.save = false;
            })
        },
        MySecurityInfo : function(){
            const api = this.$store.state.baseURL;
            
            this.$http.post(`${api}/member/setting/my_security_info`,{}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        if(res.data.info.send_email =='Y'){
                            this.emailArea = true
                        }else {
                            this.emailArea = false
                            this.email_chk = true
                        }

                        if(res.data.info.send_otp =='Y'){
                            this.otpArea = true
                        }else {
                            this.otpArea = false
                            this.otp_chk = true
                        }

                        if(res.data.info.send_pin == "N"){
                            this.$router.push('/'+this.$i18n.locale+'/page/withdrawals/'+this.token);
                        }

                    }else if(res.data.code == '9999'){
                        this.$alert(this.$t('signout.1')).then(()=>{
                            this.$store.dispatch('SETLOGOUT').then(()=>{
                                this.$router.push({name:'Signin'});
                            });
                        })
                    }
                }
            })
        },
        AuthCheck: function(){
            if(this.emailArea || this.otpArea){
                this.infoPp = true;
                if(this.emailArea) this.EmailSecurity();
            }
            else{
                this.confirm_chk = true;
            }
        },
        EmailSecurity : function(){
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/common/email_security`,{}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        this.$alert(this.$t('transaction.20'));
                    }
                }
            })
        },
        EmailCodeCheck : function(){
            const api = this.$store.state.baseURL;
            const code = this.email_code;
            
            this.$http.post(`${api}/member/common/email_code_check`,{code}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        this.$alert(this.$t('transaction.21')).then(
                        ()=>{
                            this.email_chk = true;
                            if(this.email_chk && this.otp_chk) this.auth_chk = true;
                        }
                    )
                    }else if(res.data.code=='100'){
                        this.$alert(this.$t('transaction.22'))
                    }
                }
            })
        },
        OTPCodeCheck : function(){
            const api = this.$store.state.baseURL;
            const code = this.otp_code;
            this.$http.post(`${api}/member/common/otp_code_check`,{code}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        this.$alert(this.$t('transaction.23')).then(
                        ()=>{
                            this.otp_chk = true;
                            if(this.email_chk && this.otp_chk) this.auth_chk = true;
                        })
                    }else if(res.data.code=='100'){
                        this.$alert(this.$t('transaction.24'));
                    }
                }

            })
        },
        PincodeCheck: function() {
            const code = this.transaction_pw;

            if(code.length != 8){
                this.$alert(this.$t('transaction.40'))
                return;
            }

            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/common/pin_code_check`,{code}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        this.WithdrawSend();
                    }else if(res.data.code=='100'){
                        this.$alert(this.$t('transaction.41'));
                    }
                }

            })
        },
        WithdrawSend : function(){
            const symbol = this.token;
            const value = this.amount;
            const to = this.address;
            const gas = this.Gas;

            const api = this.$store.state.baseURL;            
            this.save = true;
            this.$http.post(`${api}/member/wallet/send`,{symbol,value,to,gas}).then(
                res => {
                    if(res.status==200){
                        if(res.data.code=='200'){
                            this.$alert(this.$t('transaction.42')).then(
                            ()=>{
                                this.$router.push('/'+this.$i18n.locale+'/page/transaction-detail');
                            })
                        }else if(res.data.code=='120'){
                            this.$alert(this.$t('transaction.43'));
                        }else if(res.data.code=='100'){
                            this.$alert(this.$t('transaction.45')).then(() => {
                                this.$router.push('/'+this.$i18n.locale+'/page/withdrawals/'+symbol);
                            });
                        }else if(res.data.code == '9999'){
                            this.$alert(this.$t('signout.1')).then(()=>{
                                this.$store.dispatch('SETLOGOUT').then(()=>{
                                    this.$router.push({name:'Signin'});
                                });
                            })
                        }
                    }
            }).finally(() => {
                this.save = false;
            })
        },
        infoPpOff: function(){
            this.email_code = '';
            this.otp_code = '';
            this.email_chk = false;
            this.otp_chk = false;
            this.auth_chk = false;
            this.MySecurityInfo();
            
            this.infoPp = false;
        },
        FinallyChk() {
            if(!this.email_chk) this.$alert(this.$t('transaction.56'))
            else if(!this.otp_chk) this.$alert(this.$t('transaction.57'));
            else {
                if(this.auth_chk){
                    this.confirm_chk = true;
                    this.infoPpOff();
                }
            }
        },        
    },
    directives: {
        focus: {
            inserted: function (el) {
                el.focus()
            }
        }
    },
}
</script>

<style scoped>
.fixed_bottom_box{
    background-color: #fff;
}

.btn-default{
    border: 1px solid #ddd;
    width: 24%;
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    padding: 0;
}
input[type=radio]:checked+.btn-default{
    background-color: #4B67F9;
    border: none;
    color: #fff;
}

</style>